.msg-page {
  min-height: 100vh;
  background-color: #E6D3A3;
}

.msg-navbar {
  background-color: #4D4D4D;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.msg-navbar-left .msg-logo {
  height: 50px;
  cursor: pointer;
}

.msg-navbar-right {
  display: flex;
  align-items: center;
}

.msg-icon {
  color: white;
  margin: 10px;
  cursor: pointer;
  font-size: 24px;
  transition: background-color 0.7s ease-out 50ms;
}

.msg-icon:hover {
  color: #e0e0e0;
}

.msg-user-icon-container {
  position: relative;
  display: inline-block;
}

.msg-user-dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 150px;
  width: auto;
  white-space: nowrap;
}

.msg-user-dropdown p.msg-username {
  font-size: 14px;
  margin-bottom: 10px;
  color: #333;
}

.msg-user-dropdown button {
  background-color: #FFD700;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  width: 100%;
  border-radius: 3px;
  text-align: center;
}

.msg-user-dropdown button:hover {
  background-color: #FFD700;
}

.msg-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 1rem;
}

.msg-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
  color: #000;
}

.msg-list {
  background-color: #4D4D4D;
  border-radius: 0.5rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
}

.msg-item {
  padding: 0.75rem;
  margin-bottom: 0.5rem;
  border-radius: 0.375rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #ffffff;
  border: 3px solid #4D4D4D;
}

.msg-item:last-child {
  margin-bottom: 0;
}

.msg-item:hover {
  background-color: #cccccc;
}

.msg-item-unread {
  background-color: #cccccc;
}

.msg-item-unread:hover {
  background-color: #b1b1b1;
}

.msg-item-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.msg-item-info {
  flex: 1;
}

.msg-sender {
  color: #000;
  margin-bottom: 0.25rem;
  font-size: 0.9rem;
}

.msg-subject {
  color: #000;
  font-size: 0.9rem;
}

.msg-sender-unread,
.msg-subject-unread {
  font-weight: bold;
}

.msg-date {
  font-size: 0.875rem;
  color: #000;
  display: flex;
  align-items: center;
  gap: 8px;
}

.msg-unread-indicator {
  width: 8px;
  height: 8px;
  background-color: #ff0000;
  border-radius: 50%;
}

.msg-modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 50;
}

.msg-modal {
  position: relative;
  background-color: white;
  border-radius: 0.5rem;
  max-width: 42rem;
  width: 100%;
  margin: 1rem;
  padding: 1.5rem;
}

.msg-modal-header {
  margin-bottom: 1rem;
}

.msg-modal-meta {
  font-size: 0.875rem;
  color: #000;
  margin-bottom: 0.5rem;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid #e5e5e5;
}

.msg-modal-subject {
  font-size: 1.25rem;
  font-weight: 600;
  margin-top: 0.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid #e5e5e5;
  color: #000;
}

.msg-modal-body {
  margin: 1rem 0;
  padding: 1rem;
  background-color: #f9f9f9;
  border-radius: 0.375rem;
  white-space: pre-wrap;
  color: #000;
}

.msg-modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
  margin-top: 1.5rem;
}

.msg-btn {
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  border: none;
  cursor: pointer;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

.msg-btn-primary {
  background-color: #FFD700;
  color: #000;
}

.msg-btn-primary:hover {
  background-color: #cfb000;
  color: #000;
}

.msg-btn-secondary {
  background-color: #e5e5e5;
  color: #000;
}

.msg-btn-secondary:hover {
  background-color: #d5d5d5;
}