body {
    background-color: #E6D3A3;
}
  
.navbar {
    background-color: #4D4D4D;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}
  
.navbar-left .logo {
    height: 50px;
}
  
.navbar-right {
    display: flex;
    align-items: center;
}
  
.icon {
    color: white;
    margin: 10px;
    cursor: pointer;
    font-size: 24px;
}
  
.logo {
    cursor: pointer;
}

.form-container {
    max-width: 400px;
    margin: 50px auto;
    padding: 20px;
    background-color: #636262;
    border-radius: 10px;
    color: #FFFFFF;
  }
  
h2 {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: bold;
}
  
.form-group {
    margin-bottom: 20px;
}
  
.form-group label {
    display: block;
    margin-bottom: 10px;
    color: #FFFFFF;
}
  
input[type="text"],
input[type="password"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}
  
.submit-button {
    width: 100%;
    padding: 10px;
    background-color: #FFD700;
    color: #000000;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.7s ease-out 50ms;
}
  
.submit-button:hover {
    background-color: #ffd90080;
}
  
.loading-container {
    display: flex;
    align-items: center;
    justify-content: center;
 }
  

  
@keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
}

button{
    background-color: #FFD700;
    color: 0000;
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-weight: bold;
    transition: background-color 0.7s ease-out 50ms;
    margin-top: 10px;
    margin-bottom: 10px;
}
  
.reset-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #636262;
    padding: 20px;
    width: 90%;
    max-width: 400px;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    color: #FFFFFF;
}

.reset-modal h3 {
    color: #FFFFFF;
    font-size: 20px;
    text-align: center;
    margin-bottom: 20px;
}

.reset-modal input[type="text"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.reset-modal button {
    width: 100%;
    padding: 10px;
    margin-top: 10px;
    font-weight: bold;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.reset-modal button:first-of-type {
    background-color: #FFD700;
    color: #000000;
}

.reset-modal button:first-of-type:hover {
    background-color: #ffd90080;
}

.reset-modal button:last-of-type {
    background-color: #ccc;
    color: #000000;
}

.reset-modal button:last-of-type:hover {
    background-color: #bbb;
    color: #000000;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}

.forgot-password {
    color: #228dff;
    text-decoration: none;
    transition: color 0.3s ease, text-decoration 0.3s ease;
}

.forgot-password:hover {
    color: #1879e0;
    text-decoration: underline;
}

.forgot-password:focus {
    outline: none;
    text-decoration: underline;
}
