body {
  overflow-x: hidden;
}

.navbar {
  background-color: #4D4D4D;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.navbar-left .logo {
  height: 50px;
}

.navbar-right {
  display: flex;
  align-items: center;
}

.icon {
  color: white;
  margin: 10px;
  cursor: pointer;
  font-size: 24px;
  transition: background-color 0.7s ease-out 50ms;
}

.icon:hover {
  color: #e0e0e0;
}

.user-icon-container {
  position: relative;
  display: inline-block;
}

.user-dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 150px;
  width: auto;
  white-space: nowrap;
}

.user-dropdown p.username {
  font-size: 14px;
  margin-bottom: 10px;
  color: #333;
}

.user-dropdown button {
  background-color: #4D4D4D;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  width: 100%;
  border-radius: 3px;
  text-align: center;
}

.user-dropdown button:hover {
  background-color: #FFD700;
}

.profile-classname-content {
  margin-top: 50px;
  display: flex;
  justify-content: center;
}

.profile-classname-card {
  background-color: #4D4D4D;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  max-width: 575px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
}

.profile-container {
  width: 100%;
  max-width: 650px;
  margin: 20px auto;
  background: #4D4D4D;
  color: #FFFFFF;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  padding: 20px;
  text-align: center;
}

.profile-header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.profile-picture {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #000000;
}

.profile-info-container {
  width: 100%;
}

.profile-info-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #000000; 
}

.profile-info-table td {
  padding: 10px 15px;
  font-size: 16px;
}

.profile-info-table tr:nth-child(odd) {
  background-color: #949494;
}

.profile-info-table tr:nth-child(even) {
  background-color: #D3D3D3;
}

.profile-info-table tr:hover {
  background-color: #FFD700;
  color: #000000;
}

.verified-text {
  color: #4CAF50;
  font-weight: bold;
  text-align: center;
}

.not-verified-text {
  color: #F44336;
  font-weight: bold;
  text-align: center;
}


.button-container {
  display: flex;
  justify-content: center;
}

.pfp-button{
  width: 150px;
}

.profile-classname-editable {
  margin-top: 15px;
  color: #FFF;
  display: flex;
  align-items: center;
}

.profile-classname-editable label {
  margin-right: 10px;
  white-space: nowrap;
}

.profile-classname-editable span {
  margin-right: auto;
  color: #FFF;
}

.profile-classname-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  width: 100%;
}

.profile-classname-input:focus {
  outline: none;
  border-color: #FFD700;
}

.profile-classname-editable button {
  background-color: #FFD700;
  color: #000;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 10px;
}

.profile-classname-editable button:hover {
  background-color: #ffd90080;
}

.profile-icon{
  width: 150px;
  height: 150px;
}

.profile-classname-picture {
  position: relative;
  width: 150px;
  height: 150px;
  margin: 0 auto;
}

.camera-icon-container {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 35px;
  height: 35px;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.camera-icon {
  color: #000000;
  font-size: 18px;
}

.camera-icon-container:hover {
  background-color: #f0f0f0;
}

.upload-label {
  cursor: pointer;
}

.profile-picture:hover {
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.5);
  background-color: rgba(0, 0, 0, 0.7);
  
}

.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
}

.spinner {
  border: 7px solid rgba(0, 0, 0, 0.1);
  border-top: 7px solid #FFD700;
  border-radius: 100%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.verified-text {
  color: rgb(0, 255, 0) !important;
  font-weight: bold;
}

.not-verified-text {
  color: rgb(255, 0, 0) !important;
  font-weight: bold;
}
