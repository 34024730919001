body {
  background-color: #E6D3A3;
}

.navbar {
  background-color: #4D4D4D;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.navbar-left {
  display: flex;
  align-items: center;
}

.navbar-left .logo {
  height: 50px;
}

.logo-title {
  margin-left: 10px;
  font-size: 1.5rem;
  font-weight: bold;
  color: transparent;
  background-image: linear-gradient(135deg, #FFD700, #FFA500);
  -webkit-background-clip: text;
  background-clip: text;
}


.navbar-right {
  display: flex;
  align-items: center;
}

.icon {
  color: white;
  margin: 10px;
  cursor: pointer;
  font-size: 24px;
  transition: background-color 0.7s ease-out 50ms;
}

.icon:hover {
  color: #e0e0e0;
}

.user-icon-container {
  position: relative;
  display: flex;
  align-items: center;
}

.user-profile {
  display: flex;
  align-items: center;
}

.user-profile img,
.icon {
  margin-left: 10px; /* Adjust space between user icon and profile image */
}

.user-dropdown {
  position: absolute;
  top: 40px;
  right: 0;
  background-color: white;
  border: 1px solid #ccc;
  padding: 10px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  z-index: 10;
  min-width: 150px;
  width: auto;
  white-space: nowrap;
}

.user-dropdown p.username {
  font-size: 14px;
  margin-bottom: 10px;
  color: #333;
}

.user-dropdown button {
  background-color: #4D4D4D;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  width: 100%;
  border-radius: 3px;
  text-align: center;
}

.user-dropdown button:hover {
  background-color: #FFD700;
}

.search-container {
  margin: 5px;
  display: flex;
  justify-content: center;
  padding-top: 20px;
}

.search-box {
  width: 100%;
  max-width: 600px;
  padding: 10px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.scholarship-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(400px, 1fr));
  gap: 20px;
  padding: 20px;
}

.scholarship-card {
  background-color: #4D4D4D;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: auto;
  margin-bottom: 0;
  transition: all 0.3s ease-in-out;
  transform: perspective(1000px);
}

.scholarship-card:hover {
  background-color: #424242;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
  transform: translateY(-8px) scale(1.05);
}

.scholarship-card:hover h2, 
.scholarship-card:hover p {
  opacity: 0.9;
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.scholarship-card h3 {
  margin-top: 0;
  font-size: 20px;
}

.card-type {
  margin-top: 5px;
  padding: 5px 10px;
  border-radius: 3px;
  font-size: 12px;
  font-weight: bold;
  color: white;
  background-color: #4caf50;
  align-self: flex-start;
}

.card-type.external {
  background-color: #f44336;
}

.scholarship-card p {
  font-size: 16px;
}

.card-bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: auto;
}

.proceed-arrow {
  cursor: pointer;
  font-size: 24px;
  color: #FFD700;
  transition: color 0.3s ease-out 50ms;
}

.proceed-arrow:hover {
  color: #000;
}

.card-type.internal {
  background-color: #4caf50;
}

.card-type.external {
  background-color: #f44336;
}

.plus-button {
  position: fixed;
  bottom: 60px;
  right: 60px;
  background-color: #FFD700;
  color: #000000;
  border: none;
  border-radius: 50%;
  width: 70px;
  height: 70px;
  font-size: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background-color 0.7s ease-out 50ms;
}

.plus-button:hover {
  background-color: #b99e00;
}

.form-group input[type="number"] {
  width: 100%;
  max-width: 50px;
  padding: 10px;
  border: 2px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  color: #333;
  outline: none;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.user-profile-img {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  object-fit: cover;
}
