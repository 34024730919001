body {
    background-color: #E6D3A3;
}

.navbar {
    background-color: #4D4D4D;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px;
}

.navbar-left .logo {
    height: 50px;
}

.form-container {
    max-width: 400px;
    margin: 40px auto;
    padding: 20px;
    background-color: #636262;
    border-radius: 10px;
    color: #FFF;
    box-sizing: border-box;
}

h2 {
    text-align: center;
    margin-bottom: 20px;
}

input {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-sizing: border-box;
}

.dropdown-reg {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff;
    color: #000000;
    box-sizing: border-box;
    appearance: none;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='10' height='10' viewBox='0 0 10 10'><polygon points='0,0 10,0 5,10' fill='%23636262'/></svg>");
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px;
}

button {
    width: 100%;
    padding: 10px;
    background-color: #FFD700;
    color: #000;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: background-color 0.7s ease-out;
}

button:hover {
    background-color: #ffd90080;
}

.error {
    color: red;
    text-align: center;
    margin-bottom: 10px;
}

.back-arrow {
    cursor: pointer;
    color: #FFD700;
    margin-bottom: 10px;
    margin-top: 10px;
    font-size: 24px;
    display: block;
    text-align: left;
}

.loading-animation {
    border: 4px solid #f3f3f3;
    border-radius: 50%;
    border-top: 4px solid #000000;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

.contact-input-container {
    display: flex;
    align-items: stretch;
}

.contact-prefix {
    padding: 10px;
    background-color: #c7c2c2;
    border: 1px solid #c0bfbf;
    border-radius: 5px 0 0 5px;
    color: #000000;
    height: 14.1px;
    display: flex;
    align-items: center;
}

.contact-input {
    flex-grow: 1;
    padding-left: 10px;
    border-radius: 0 5px 5px 0;
    border: 1px solid #ccc;
    height: 36px;
}

.contact-input::-webkit-inner-spin-button,
.contact-input::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}


.password-container {
    position: relative;
    display: flex;
    flex-direction: column;
  }
  
  .floating-box {
    position: absolute;
    top: 0;
    left: 105%; 
    width: 250px;
    padding: 10px;
    background-color: #f9f9f9;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    font-size: 12px;
    z-index: 1000; 
  }
  
  .floating-box p {
    font-weight: bold;
    color: #333;
    margin: 0 0 5px 0;
  }
  
  .floating-box ul {
    list-style-type: none;
    padding-left: 10px;
    margin: 0;
  }
  
  .floating-box li {
    color: red;
  }
  
  .floating-box li.valid {
    color: green;
  }
  
  .password-container input {
    margin-bottom: 10px;
  }
  