.home-container {
  font-family: 'Inter', sans-serif;
  color: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

.home-navbar {
  position: sticky;
  top: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.25rem 2rem;
  background: rgba(77, 77, 77, 0.95);
  backdrop-filter: blur(10px);
  z-index: 1000;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.1);
}

.home-logo {
  font-size: 2rem;
  font-weight: 700;
  background: linear-gradient(135deg, #FFD700, #FFA500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.home-login-button {
  font-size: 25px;
}

.home-nav-links {
  display: flex;
  gap: 2rem;
}

.home-link {
  color: #FFD700;
  text-decoration: none;
  padding: 0.5rem 1rem;
  border-radius: 8px;
  transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
  font-weight: 500;
}

.home-link:hover {
  background-color: rgba(255, 215, 0, 0.1);
  transform: translateY(-2px);
}

.home-link.active {
  background-color: #FFD700;
  color: #4D4D4D;
  font-weight: 600;
}

.home-hero-section {
  min-height: calc(100vh - 80px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: linear-gradient(135deg, #333333, #4D4D4D);
  padding: 4rem 6rem;
  position: relative;
  overflow: hidden;
}

.home-hero-text {
  flex: 1;
  max-width: 600px;
  position: relative;
  z-index: 2;
}

.home-hero-text-title {
  font-size: 4.4rem;
  font-weight: 800;
  line-height: 1.2;
  margin-bottom: 1.5rem;
  background: linear-gradient(135deg, #FFD700, #FFA500);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  animation: fadeInUp 0.8s ease-out;
}

.home-hero-text p {
  font-size: 1.5rem;
  line-height: 1.6;
  color: #E0E0E0;
  margin-bottom: 2rem;
  animation: fadeInUp 0.8s ease-out 0.2s backwards;
}

.home-hero-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 2;
  animation: floatAnimation 6s ease-in-out infinite;
}

.home-hero-image img {
  max-width: 100%;
  height: auto;
  max-height: 500px;
  object-fit: contain;
  filter: drop-shadow(0 10px 30px rgba(0, 0, 0, 0.3));
}

.home-features-section,
.home-mobile-section,
.home-partner-section {
  background: #f5e1ad;
  color: #4D4D4D;
  text-align: center;
  padding: 4rem 2rem;
}

.home-about-section,
.home-our-team-section,
.home-contact-section {
  background: #E6D3A3;
  color: #4D4D4D;
  text-align: center;
  padding: 4rem 2rem;
}

.team-cards-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 2rem;
  margin-top: 2rem;
}

.team-card {
  background-color: #FFD700;
  border-radius: 1rem;
  padding: 1.5rem;
  width: 250px;
  text-align: center;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  margin-bottom: 20px;
}

.team-card h3{
  color: #000;
}


.team-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
}

.team-image {
  width: 200px;
  height: 200px;
  border-radius: 10px;
  object-fit: cover;
  margin-bottom: 1rem;
}

.features-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 2rem;
  margin-top: 3rem;
}

.feature-card {
  background-color: #FFD700;
  color: #4D4D4D;
  width: 300px;
  border-radius: 1rem;
  padding: 1.5rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.feature-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.feature-icon {
  font-size: 90px;
  color: #000000;
  margin-bottom: 15px;
}

.feature-card h3{
  color: #000;
}

.home-contact-button {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #FFD700, #FFA500);
  color: #4D4D4D;
  font-size: 1.125rem;
  font-weight: 600;
  padding: 1rem 2rem;
  border: none;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(255, 215, 0, 0.3);
  transition: all 0.3s ease;
  cursor: pointer;
  width: 180px;
}

.home-contact-button:hover {
  transform: translateY(-3px);
  box-shadow: 0 6px 25px rgba(255, 215, 0, 0.4);
}

.home-footer {
  background-color: #4D4D4D;
  color: #FFFFFF;
  text-align: center;
  padding: 2rem 1rem;
  margin-top: 2rem;
}

.mobile-qr {
  width: 250px;
  height: auto;
}


#partner-schools-section{
  margin-bottom: 100px;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes floatAnimation {
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-20px);
  }
}

@media (max-width: 1024px) {
  .home-hero-section {
    padding: 3rem 2rem;
    flex-direction: column;
    text-align: center;
    gap: 3rem;
  }

  .home-hero-text-title {
    font-size: 3rem;
  }

  .home-hero-text {
    max-width: 100%;
  }
}

@media (max-width: 768px) {
  .home-navbar {
    padding: 1rem;
  }

  .home-nav-links {
    gap: 1rem;
  }

  .home-hero-text-title {
    font-size: 2.5rem;
  }

  .home-hero-image img {
    max-height: 300px;
  }

  .features-container {
    gap: 1rem;
  }

  .feature-card {
    width: 100%;
    max-width: 300px;
  }

  .mobile-qr {
    width: 120px;
  }
}