body {
  background-color: #E6D3A3;
}

.navbar {
  background-color: #4D4D4D;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.logo {
  height: 50px;
  cursor: pointer;
}

.icon {
  color: white;
  margin: 10px;
  cursor: pointer;
  font-size: 24px;
}

.back-arrow {
  font-size: 24px;
  margin: 0;
  cursor: pointer;
  color: #FFD700;
  transition: color 0.3s ease-out 50ms;
}

.back-arrow:hover {
  color: #000000;
}

.form-container-list {
  background-color: #4D4D4D;
  padding: 30px;
  max-width: 700px;
  margin: 50px auto;
  border-radius: 15px;
}

.scholarship-info {
  text-align: center;
}

.scholarship-info h2 {
  margin-top: 0;
  color: #FFFFFF;
}

.scholarship-info p {
  color: #FFFFFF; 
  font-size: 14px;
}

h3 {
  color: #FFFFFF;
}

.student-list {
  margin-top: 0;
}

.student-card {
  display: flex;
  align-items: center;
  background-color: #FFD700;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 5px;
  position: relative;
  flex-wrap: wrap;
  transition: all 0.3s ease-in-out;
  transform: perspective(1000px);
}

.student-card:hover {
  background-color: #caac00;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.student-profile {
  flex-shrink: 0;
  margin-right: 15px;
}

.student-profile img {
  width: 70px;
  height: 70px;
  border-radius: 50%;
  object-fit: cover;
}

.student-info {
  flex-grow: 1;
  max-width: 70%;
}

.student-info h3 {
  margin: 0;
  font-size: 18px;
  color: #000000;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.student-info p {
  margin: 5px 0 0;
  font-size: 14px;
  color: #000000;
}

.student-actions {
  margin-left: auto;
}

.proceed-arrow-stud {
  font-size: 24px;
  color: #575757;
  cursor: pointer;
  margin-bottom: 25px;
  transition: color 0.3s ease-out 50ms;
}

.proceed-arrow-stud:hover {
  color: #000000;
}

.status-indicator {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
}

.status-indicator.Pending {
  background-color: #FFA500;
  color: #000000;
}

.status-indicator.Processing {
  background-color: #2196F3;
  color: #000000; 
}

.status-indicator.Approved {
  background-color: #4CAF50;
  color: #000000; 
}

.status-indicator.Rejected {
  background-color: #FF4D4D;
  color: #000000; 
}

.program-type {
  font-size: 14px;
  font-weight: bold;
  padding: 5px 10px;
  border-radius: 5px;
  display: inline-block;
}

.program-type.internal {
  background-color: #4CAF50;
  color: white;
}

.program-type.external {
  background-color: #FF4D4D;
  color: white;
}

.p-studentList{
  color: white;
  text-align: center;
  margin-top: 50px;
}

.spinner-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
}

.edit-icon {
  color: #FFD700;
  transition: color 0.3s ease-out 50ms;
  cursor: pointer;
}

.edit-icon:hover {
  color: #000000;
}

.prog-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.prog-modal {
  background: #FFFFFF;
  width: 80%;
  max-width: 1500px;
  max-height: 90vh;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  z-index: 10000;
}

.prog-modal-header {
  background: #4D4D4D;
  color: #FFD700;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.prog-update-btn {
  color: #FFD700;
  transition: color 0.3s ease-out 50ms;
  cursor: pointer;
  border: none;
  color: #4D4D4D;
  padding: 8px 16px;
  cursor: pointer;
  font-size: 16px;
  border-radius: 4px;
  max-width: 100px;
  margin: 0;
}

.prog-update-btn:hover {
  background-color: #c0a403;
  color: #000000;
}

.prog-modal-title-container {
  display: flex;
  align-items: center;
}

.prog-modal-title {
  font-size: 24px;
  margin: 0;
  padding: 0;
}

.prog-close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
}

.prog-modal-content {
  display: flex;
  justify-content: space-between;
  gap: 20px;
  overflow-y: auto;
}

.prog-modal-column {
  flex: 1;
  padding: 10px;
  max-height: 800px;
}

.prog-modal-column ul {
  padding-left: 20px;
  padding-bottom: 15px;
}

.prog-modal-column p, .prog-modal-column ul {
  margin-bottom: 10px;
}

.prog-modal-btm-gap {
  margin-bottom: 10px;
}