body {
  overflow-x: hidden;
  background-color: #E6D3A3;
}

.navbar {
  background-color: #4D4D4D;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
}

.logo {
  height: 50px;
  cursor: pointer;
}

.icon {
  color: white;
  margin: 10px;
  cursor: pointer;
  font-size: 24px;
}

.back-arrow {
  font-size: 24px;
  color: #FFD700;
  cursor: pointer;
  margin-bottom: 10px;
  transition: color 0.3s ease-out 50ms;
}

.back-arrow:hover {
  color: #000000;
}

.sp-form-container-list {
  background-color: #4D4D4D;
  padding: 30px;
  max-width: 700px;
  margin: 50px auto;
  border-radius: 15px;
  position: relative;
}

.sp-student-profile-container {
  padding: 20px;
  border-radius: 8px;
  max-width: 600px;
  margin: 20px auto;
}

.sp-profile-wrapper {
  display: flex;
  align-items: center;
}

.sp-student-profile img {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  margin-right: 20px;
}

.sp-student-info h3 {
  margin: 0;
  color: #FFFFFF;
  font-size: 24px;
  margin-left: 30px;
}

.sp-student-info p {
  color: #FFFFFF;
  font-size: 16px;
  margin-left: 30px;
}

.sp-student-documents {
  width: 100%;
  margin-top: 20px;
}

.sp-document-row {
  background-color: #FFD700;
  color: black;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 5px;
  transition: all 0.3s ease-in-out;
  transform: perspective(1000px);
}

.sp-document-row:hover {
  background-color: #caac00;
  box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
}

.sp-document-actions {
  display: flex;
  gap: 10px;
}

.sp-download-icon,
.sp-uncheck-icon{
  cursor: pointer;
  font-size: 30px;
  height: 30px;
  transition: color 0.3s ease-out 50ms, box-shadow 0.3s ease-out 50ms;
  color: #575757;
}

.sp-download-icon:hover,
.sp-uncheck-icon:hover,
.sp-checked-icon:hover {
  color: #000000;
}

.sp-checked-icon {
  cursor: pointer;
  font-size: 30px;
  height: 30px;
  transition: color 0.3s ease-out 50ms, box-shadow 0.3s ease-out 50ms;
  color: green;
}

.sp-checked-icon:hover{
   color: darkgreen;
}

.sp-approve-container {
  margin-top: 20px;
  display: flex;
  gap: 10px;
}

.sp-approve-button {
  background-color: green;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 200px;
}

.sp-reject-button {
  background-color: red;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 200px;
}

.sp-approve-button:hover {
  background-color: darkgreen;
}

.sp-reject-button:hover {
  background-color: darkred;
}


.sp-no-documents {
  background-color: #FFD700;
  color: black;
  padding: 10px;
  margin: 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px;
}

.sp-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.sp-modal-content {
  position: relative;
  background: #FFFFFF;
  color: #000000;
  padding: 20px;
  width: 650px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
}

.sp-modal-column-p{
  padding-bottom: 5px;
}

.sp-modal-left {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.sp-modal-profile-pic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #000000;
}

.sp-modal-details-container {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.sp-modal-info {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.sp-modal-column {
  flex: 1;
  padding: 0 3px;
}

.sp-modal-column p {
  margin: 0;
  font-size: 20px;
}


.sp-close-modal-icon {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: 24px;
  cursor: pointer;
  color: #000000;
  transition: color 0.3s ease-out 50ms, box-shadow 0.3s ease-out 50ms;
  z-index: 1;
}

.sp-close-modal-icon:hover {
  color: #FFD700;
}

.sp-modal-header {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.sp-modal-profile-pic {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
}

.sp-modal-details-container {
  padding: 10px 20px;
}

.sp-modal-details-table {
  width: 100%;
  border-collapse: collapse;
  border: 1px solid #000000; 
}

.sp-modal-details-table td {
  padding: 12px 10px;
  font-size: 18px;
  vertical-align: top;
  color: #000000;
}

.sp-modal-details-table tr:nth-child(odd) {
  background-color: #949494;
}

.sp-modal-details-table tr:nth-child(even) {
  background-color: #D3D3D3;
}

.sp-modal-details-table tr:hover {
  background-color: #FFD700;
}

.sp-modal-details-table td:first-child {
  font-weight: bold;
  width: 35%;
}

.sp-student-btns {
  display: flex;
  gap: 10px;
  margin-left: 30px;  
}

.sp-info-btn {
  padding: 10px 15px;
  cursor: pointer;
  width: 100px;
}

.sp-msg-btn {
  padding: 10px 15px;
  cursor: pointer;
  width: 150px;
}

.sp-modal-form input{
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  background-color: #dbdbdb;
  color: #000000;
  border: none;
  border-radius: 5px;
}

.sp-modal-form h3 {
  color: #000000;
  font-size: 20px;
  font-family: Arial, Helvetica, sans-serif;
}

.sp-modal-form textarea {
  width: 97%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  background-color: #eeeeee;
  color: #000000;
  border: none;
  border-radius: 5px;
  font-family: Arial, Helvetica, sans-serif;
}

.sp-modal-form input::placeholder {
  color: #FFFFFF;
  opacity: 1;
}

.sp-modal-textarea {
  height: 200px;
  resize: none;
}

.sp-send-btn {
  padding: 10px;
  font-size: 16px;
  background-color: #FFD700;
  color: #000000;
  cursor: pointer;
  border: none;
  border-radius: 5px;
  width: 100%; 
}

.sp-warning-icon {
  color: lightblue;
  font-size: 150px;
}

.sp-send-btn:hover {
  background-color: #cfb000;
}

.sp-modal-notif-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.sp-modal-notif-content {
  background: #4D4D4D;
  color: #FFFFFF;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  text-align: center;
  max-width: 350px;
}

.sp-modal-notif-msg {
  font-weight: 500;
}

.sp-button-container {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-top: 20px; 
}

.sp-btn-yes {
  background-color: #4CAF50;
}

.sp-btn-yes:hover {
  background-color: #337937;
}

.sp-btn-no {
  background-color: #F44336;
}

.sp-btn-no:hover {
  background-color: #a02424;
}

.sp-remarks-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin-top: 10px;
  width: 100%;
}

.sp-remarks-textarea {
  width: 95%;
  min-height: 80px;
  padding: 8px;
  margin-bottom: 10px;
  resize: vertical;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-family: Arial, Helvetica, sans-serif;
}

.sp-save-remarks-button {
  padding: 10px 30px;
  width: auto;
  background-color: #FFD700;
  color: #000000;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  align-self: flex-end;
}

.sp-spinner {
  width: 15px;
  height: 15px;
  border: 2px solid #fff;
  border-top: 2px solid #FFD700;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.sp-modal-input {
  width: 100%;
  padding: 10px;
  margin-bottom: 10px;
  font-size: 14px;
  background-color: #eeeeee;
  color: #000000;
  border: none;
  border-radius: 5px;
}

.sp-modal-input option {
  background-color: #eeeeee;
  color: #000000;
}

.sp-modal-input option:disabled {
  color: #535353;
}

.program-name {
  color: #FFD700;
  font-weight: bold;
}

.sp-file-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1px 5px;
  background-color: #eeeeee;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 5px;
  width: 100%;
  box-sizing: border-box;
}

.sp-warning-icon {
  font-size: 160px;
  color: rgb(135, 220, 248);
}

.sp-file-item span {
  flex-grow: 1;
  margin-right: 10px; 
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis; 
}

.sp-remove-file {
  background: none;
  border: none;
  color: #ff0000;
  font-size: 20px;
  cursor: pointer;
  margin-left: auto;
  padding: 0;
  max-width: 20px;
}
